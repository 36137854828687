// Theme colors
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$primary: #687ae8;
$secondary: #95aac9;
$success: #0c9;
$info: #19b5fe;
$warning: #f7bc06;
$danger: #f2545b;

$light:         $gray-200;
$dark:          #28304e;

// Sidebar brand
$sidebar-brand-bg: $dark;

// Splash
$splash-bg: $dark;

// Wrapper
$wrapper-bg: $dark;
